import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'inputOn', 'inputOff', 'wrapperOn', 'wrapperOff']

  updateInputs() {
    if (this.checkboxTarget.checked) {
      this.inputOffTargets.forEach(input => input.setAttribute("disabled", ""))
      this.inputOnTargets.forEach(input => input.removeAttribute("disabled"))
      this.wrapperOnTargets.forEach(wrapper => wrapper.classList.remove("hidden"))
      this.wrapperOffTargets.forEach(wrapper => wrapper.classList.add("hidden"))
    } else {
      this.inputOnTargets.forEach(input => input.setAttribute("disabled", ""))
      this.inputOffTargets.forEach(input => input.removeAttribute("disabled"))
      this.wrapperOffTargets.forEach(wrapper => wrapper.classList.remove("hidden"))
      this.wrapperOnTargets.forEach(wrapper => wrapper.classList.add("hidden"))
    }
  }

  connect() {
    this.updateInputs()
  }
}
