// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["btnContextFilter",
                    "grid",
                    "previewContainer",
                    "form",
                    "inputContextType",
                    "inputContextId",
                    "sendButton",
                    "divSendConfirmation",
                    "divSendConfirmationCanal",
                    "select",
                    "supplierContactCheckboxes",
                    "collaboratorContactCheckboxes"]

  initialize() {
    this.imgLoaded               = this.imgLoaded.bind(this)
    // this.updateEventListCallback = this.updateEventListCallback.bind(this)
  }

  // rewriteUrl() {
  //   history.replaceState(history.state, '', `?${this.searchParams.toString()}`);
  // }

  // updateEventListCallback(html) {
  //   this.gridTarget.querySelectorAll('.event').forEach(e => e.remove())
  //   this.gridTarget.insertAdjacentHTML('afterbegin', html);
  //   this.waitingImagesLoadedAndscrollToTarget()
  // }

  // updateEventList() {
  //   this.rewriteUrl()
  //   fetch(window.location.href, { headers: { 'X-Requested-With': 'XMLHttpRequest' }})
  //     .then(response => response.text())
  //     .then(this.updateEventListCallback)
  // }

  // setChannel(contextType, contextId) {
  //   this.btnContextFilterTargets.forEach((btn) => {
  //     btn.classList.toggle('active', btn.dataset.contextId == contextId && btn.dataset.contextType == contextType)
  //   })

  //   this.searchParams.set('context_id', contextId)
  //   this.searchParams.set('context_type', contextType)
  //   this.inputContextTypeTarget.value = contextType
  //   this.inputContextIdTarget.value = contextId
  //   this.updateEventList()
  // }

  // btnContextFilterClick(e) {
  //   e.preventDefault();
  //   this.setChannel(e.currentTarget.dataset.contextType, e.currentTarget.dataset.contextId)
  //   this.divSendConfirmationCanalTarget.innerText = e.currentTarget.dataset.canalName
  //   this.sendCancel()
  // }

  selectEventTypeChanged(e) {
    const selectElement = e.currentTarget
    const selectedItem = selectElement.options[selectElement.selectedIndex];

    this.updateFormCss(selectedItem)
    this.toggleContactCheckboxes(selectedItem)

    this.formTarget.querySelector("input[name='event[type]']").value = selectedItem.dataset.type
  }

  sendButtonClick() {
    this.sendButtonTarget.classList.add('hidden');
    this.divSendConfirmationTarget.classList.remove('hidden');
  }
  sendConfirm() {
    this.formTarget.submit()
  }
  sendCancel() {
    this.divSendConfirmationTarget.classList.add('hidden');
    this.sendButtonTarget.classList.remove('hidden');
  }


  previewFile(e) {
    this.previewContainerTarget.innerHTML = ""
    this.previewContainerTarget.classList.remove('hidden')
    const img = document.createElement('img');
    img.src = e.currentTarget.src
    img.classList.add("max-h-full")
    this.previewContainerTarget.appendChild(img);
  }

  unPreviewFile() {
    this.previewContainerTarget.classList.add('hidden')
    this.previewContainerTarget.innerHTML = ""
  }

  imgLoaded() {
    this.counterImgsLoaded++;
    if (this.counterImgsLoaded === this.imgs.length)
      this.scrollToTarget()
  }

  scrollToBottom() {
    this.gridTarget.scroll({ top: this.gridTarget.scrollHeight })
  }

  scrollToTarget() {
    const urlParams = new URLSearchParams(window.location.search);
    const event_id = urlParams.get('event_id')
    if (event_id) {
      const element = document.querySelector(`#event_${event_id}`);
      element.scrollIntoView(true);
    } else {
      this.scrollToBottom()
    }
  }

  waitingImagesLoadedAndscrollToTarget() {
    this.counterImgsLoaded = 0;
    this.imgs = this.gridTarget.querySelectorAll('img');
    this.imgs.forEach((img) => {
      if (img.complete)
        this.imgLoaded();
      else {
        img.addEventListener('load', this.imgLoaded);
        img.addEventListener('error', this.imgLoaded);
      }
    });
    if (this.imgs.length === 0)
      this.scrollToTarget()
  }

  updateFormCss(selectedItem) {
    if (selectedItem.dataset.formCss) {
      for (let i = this.formTarget.classList.length - 1; i >= 0; i--) {
        const className = this.formTarget.classList[i];
        if (className.startsWith('bg-')) {
          this.formTarget.classList.remove(className);
        }
      }
      this.formTarget.classList.add(selectedItem.dataset.formCss)
    }
  }

  toggleContactCheckboxes(selectedItem) {
    if (selectedItem.dataset.type == 'Event::WaitingSupplierInformation')
      this.supplierContactCheckboxesTarget.style.display = 'block'
    else
      this.supplierContactCheckboxesTarget.style.display = 'none'

    if (['Event::TeamInformation', 'Event::TeamMessage'].includes(selectedItem.dataset.type))
      this.collaboratorContactCheckboxesTarget.style.display = 'block'
    else
      this.collaboratorContactCheckboxesTarget.style.display = 'none'
  }

  connect() {
    if (this.hasSelectTarget) {
      const selectedItem = this.selectTarget.options[this.selectTarget.selectedIndex];

      this.updateFormCss(selectedItem)
      this.toggleContactCheckboxes(selectedItem)
    }

    window.item_flux_controller = this
    this.waitingImagesLoadedAndscrollToTarget()
    this.searchParams = new URLSearchParams(window.location.search)
  }
}
