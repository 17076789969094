import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accordionContent"]

  toggle() {
    const contentHeight = this.accordionContentTarget.dataset.contentHeight;
    this.accordionContentTarget.classList.toggle("h-0");
    this.accordionContentTarget.classList.toggle(`h-${contentHeight}`);
  }
}
