import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'modal', 'button' ]

  openId(id) {
    const mod = this.modalTargets.find(modal => modal.dataset.modalId == id)
    if (mod)
      mod.classList.add('modal-open');
    else
      console.error(`modal with id ${id} not found`)
  }

  open(event) {
    this.openId(event.currentTarget.dataset.modalTargetId);
  }

  close(event) {
    this.modalTargets.forEach(modal => modal.classList.remove('modal-open'));
  }
}
