
import { Controller } from "@hotwired/stimulus"

import { Loader } from "@googlemaps/js-api-loader"


export default class MapController extends Controller {
  static targets = ["mapContainer"]

  addMarker(markerData) {
    const coordinates = { lat: parseFloat(markerData.lat), lng: parseFloat(markerData.lng) }
    const marker = new google.maps.Marker({
      map: this.map,
      position: coordinates,
      icon: markerData.icon_url,
      draggable: markerData.draggable,
      title: 'Lieu de livraison'
    });
    this.bounds.extend(marker.position)
    this.markers.push(marker)
  }

  centerMap() {
    if (this.markers.length > 1)
      this.map.fitBounds(this.bounds);
    else if (this.markers.length == 1)
      this.map.setCenter(this.markers[0].getPosition())
  }

  /////////////
  // INIT //
  /////////////

  connect(callback = null) {
    this.markers = []
    this.markersData = JSON.parse(this.element.dataset.markers)
    const loader = new Loader({ apiKey: this.element.dataset.googleApiKey, version: "weekly" });
    const mapContainer = this.hasMapContainerTarget ? this.mapContainerTarget : this.element

    loader.load().then(() => {
      this.bounds = new google.maps.LatLngBounds();
      this.map = new google.maps.Map(mapContainer, {
        zoom: 19,
      });
      this.markersData.forEach(this.addMarker, this)
      this.centerMap()
      if (callback) { callback() }
    });
  }
}
