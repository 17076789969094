import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'select', 'legend' ]

  showLegend(legendId) {
    this.legendTargets.forEach((legendElement) => {
      legendElement.classList.toggle('hidden', legendElement.dataset.legendId != legendId)
    })
  }

  change() {
    const option = this.selectTarget.options[this.selectTarget.selectedIndex]
    const legendId = option.value
    this.showLegend(legendId)
  }

  connect() {
    this.change()
  }
}
