import '../stylesheets/application.scss'
import 'select2/dist/css/select2.min.css';

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@fortawesome/fontawesome-free/js/all";
import { initFlatpickr } from "../plugins/init_flatpickr";

const images = require.context("../images", true)

Rails.start()
ActiveStorage.start()

import "controllers"

// FIX FOCUS SELECT2
// https://github.com/select2/select2/issues/5993#issuecomment-800927000
$(document).on('select2:open', () => {
  document.querySelector('.select2-search__field').focus();
});

document.addEventListener("turbo:load", function () {
  initFlatpickr();
});

