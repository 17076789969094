// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import 'select2';

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "parentInput", "childInput" ]

  connect() {
    $(this.childInputTarget).select2({
      width: '100%',
      ajax: {
        url: this.childInputTarget.dataset.url,
        headers: {"X-CSRF-Token": document.querySelector("[name='csrf-token']").content},
        data: (params) => {
          const data = { search: params.term }
          data[this.childInputTarget.dataset.parentKey] = this.parentInputTarget.value
          return data
        }
      }
    });

    this.parentInputTarget.addEventListener('change', (e) => {
      $(this.childInputTarget).val(null).trigger('change');
      $(this.childInputTarget).select2('open');
    })
  }
}
