import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['select', 'checkboxes' ]

  checkboxesTargetsForSelect(id) {
    return this.checkboxesTargets.filter((checkbox) => checkbox.dataset[this.mappingKey] == id)
  }

  selectChanged(e) {
    this.checkboxesTargets.forEach((checkbox) => { // uncheck and hide all checkboxes
      checkbox.checked = false
      checkbox.parentElement.classList.add('hidden')
    })

    const rightCheckboxes = this.checkboxesTargetsForSelect(e.currentTarget.value)
    rightCheckboxes.forEach((checkbox) => checkbox.parentElement.classList.remove('hidden'))
    if (rightCheckboxes.length == 1) {
      rightCheckboxes[0].checked = true
    }
  }

  connect() {
    this.mappingKey = this.element.dataset.mappingKey
  }
}
