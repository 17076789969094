// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";
import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['listHiddenColumns', 'listDisplayedColumns']

  initialize() {
    this.synchroBack = this.synchroBack.bind(this)
  }

  synchroBack(event) {
    fetch(`/admin/display_preferences/${this.displayPreferenceId}/update_table`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ kindPreference: this.kindPreference, columns: this.displayedColumns() })
    })
  }

  displayedColumns() {
    return Array.from(this.listDisplayedColumnsTarget.querySelectorAll('li')).map((li) => li.dataset.key)
  }

  connect() {
    window.TableItemDisplayPreferencesController = this
    const options = {
      group: 'shared', // set both lists to same group
      animation: 200,
      ghostClass: 'ghost',
      onEnd: this.synchroBack
    }

    this.displayPreferenceId = this.targets.element.dataset.displayPreferenceId
    this.kindPreference = this.targets.element.dataset.kindPreference

    new Sortable(this.listHiddenColumnsTarget, options);
    new Sortable(this.listDisplayedColumnsTarget, options);
  }
}





