// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import 'select2';

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "countryInput", "cityInput" ]

  connect() {
    $(this.cityInputTarget).select2({
      width: '100%',
      ajax: {
        url: '/cities/search',
        data: (params) => {
          return {
            search: params.term,
            type: 'public',
            country_id: this.countryInputTarget.value
          }
        }
      }
    });

    // https://psmy.medium.com/rails-6-stimulus-and-select2-de4a4d2b59e4
    // select2 has his own event, and we want to fire the native change event
    $(this.cityInputTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });


    this.countryInputTarget.addEventListener('change', (e) => {
      $(this.cityInputTarget).val(null).trigger('change');
    })
  }
}
