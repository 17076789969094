import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "countFilters"]

  connect() { this.updateCountFilters() }
  inputUpdated () { this.updateCountFilters() }

  get checkboxesCheckedCount () { return this.formTarget.querySelectorAll('input[type = "checkbox"]:checked').length }
  get optionsSelectedCount () { return this.formTarget.querySelectorAll("select option:checked:not([value=''])").length }
  get filtersActiveCount () { return (this.checkboxesCheckedCount + this.optionsSelectedCount) }

  updateCountFilters(){ this.countFiltersTarget.innerText = this.filtersActiveCount }
}
