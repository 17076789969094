
import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class TippyController extends Controller {
  connect() {
    if (this.element.dataset['tippyContent'] === undefined)
      return(console.warn(`"data-tippy-content" is undefined for ${this.element.innerHTML}`))

    tippy(this.element)
  }
}
