
import MapController  from './map_controller.js'
import debounce  from 'lodash.debounce'

export default class extends MapController {
  static targets = ["inputId",
    "inputLine1",
    "inputLine2",
    "inputLine3",
    "inputCountryId",
    "inputCityId",
    "inputLatitude",
    "inputLongitude",
    "inputDefaultDelivery",
    "selectAddress",
    "spanLatitude",
    "spanLongitude"]
  updateInputCoordinates(lat, lng) {
    this.inputLatitudeTarget.value = lat
    this.inputLongitudeTarget.value = lng
    this.spanLatitudeTarget.innerText = lat
    this.spanLongitudeTarget.innerText = lng
  }

  fullAddress() {
    const country = this.inputCountryIdTarget.options[this.inputCountryIdTarget.selectedIndex].text
    const city = this.inputCityIdTarget.options[this.inputCityIdTarget.selectedIndex].text
    return `${this.inputLine1Target.value} ${this.inputLine2Target.value} ${this.inputLine3Target.value} ${city} ${country}`
  }

  updatePosition() {
    if (this.inputLatitudeTarget.value && this.inputLongitudeTarget.value) {
      const latitude = parseFloat(this.inputLatitudeTarget.value)
      const longitude = parseFloat(this.inputLongitudeTarget.value)
      const latLng = { lat: latitude, lng: longitude }
      this.map.setCenter(latLng);
      this.map.setZoom(19);
      this.marker.setPosition(latLng);
      this.marker.setOptions({ visible: true })
    } else {
      this.map.setZoom(3);
      this.marker.setOptions({ visible: false })
    }
  }

  updateInputs(attributes) {
    this.inputIdTarget.value = attributes.id || ''
    this.inputLine1Target.value = attributes.line1 || ''
    this.inputLine2Target.value = attributes.line2 || ''
    this.inputLine3Target.value = attributes.line3 || ''
    if (attributes.country_id)
      this.inputCountryIdTarget.value = attributes.country_id
    $(this.inputCityIdTarget).val(attributes.city_id).trigger('change');
    this.inputLatitudeTarget.value = attributes.latitude || ''
    this.inputLongitudeTarget.value = attributes.longitude || ''
    this.inputDefaultDeliveryTarget.checked = attributes.default_billing

    this.updatePosition()
  }

  geocodeFromInput() {
    if (this.inputLine1Target.value && this.inputLine1Target.value != '' &&
      this.inputCountryIdTarget.value && this.inputCountryIdTarget.value != '' &&
      this.inputCityIdTarget.value && this.inputCityIdTarget.value != '') {

      this.geocoder.geocode({ 'address': this.fullAddress() }, (results, status) => {
        if (status == 'OK') {
          const coordinates = results[0].geometry.location;
          this.updateInputCoordinates(coordinates.lat(), coordinates.lng())
          this.updatePosition()
        }
      });
    }
  }

  /////////////
  // ACTIONS //
  /////////////

  newAddressClicked() {
    this.updateInputs({})
  }

  selectAddressChanged() {
    const selectedOption = this.selectAddressTarget.options[this.selectAddressTarget.selectedIndex]
    this.updateInputs(JSON.parse(selectedOption.dataset.attributes))
  }

  markerDragEnd(event) {
    this.updateInputCoordinates(event.latLng.lat(), event.latLng.lng())
  }

  inputChanged() {
    this.geocodeFromInput()
  }

  /////////////
  // INIT //
  /////////////

  initialize() {
    this.markerDragEnd = this.markerDragEnd.bind(this)
    this.inputChanged = debounce(this.inputChanged, 1000).bind(this)
  }

  connect() {
    super.connect(() => {
      this.geocoder = new google.maps.Geocoder();
      this.marker = this.markers[0]
      this.marker.addListener('drag', this.markerDragEnd)
    })
  }
}
