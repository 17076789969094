// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import 'select2';

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["badge", "list", "bell"]

  updateBell(notificationCount) {
    if (notificationCount > 0) {
      this.badgeTarget.classList.remove('hidden')
      this.badgeTarget.innerText = notificationCount
      this.bellTarget.classList.remove('text-gray-300')
    } else {
      this.badgeTarget.classList.add('hidden')
      this.bellTarget.classList.add('text-gray-300')
    }
  }

  connect() {
    fetch(`/notifications/count.json`)
      .then((response) => response.json())
      .then((data) => {
        this.updateBell(data.notifications.count)
        if (data.notifications.count > 0) {
          this.haveNotification = true
        }
      })
  }
}
