import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'div']

  toggleViewCheckbox() {
    const isChecked = this.checkboxTarget.checked
    this.divTarget.classList.toggle('hidden', !isChecked)
  }

  connect() {
    this.toggleViewCheckbox()
  }
}
