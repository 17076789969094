import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'badge', 'listNames' ]

  initialize() {
    this.inputChanged = this.inputChanged.bind(this)
  }

  updateBadge() {
    console.log(this.inputTarget.files)
    const nbFiles = this.inputTarget.files.length

    this.badgeTarget.innerHTML = nbFiles
    this.badgeTarget.classList.toggle('hidden', nbFiles == 0);
  }

  updateFileNames() {
    const files = this.inputTarget.files

    this.listNamesTarget.innerHTML = ''




    Array.from(document.querySelector('#event_files').files).forEach((file) => {
      const li = document.createElement("li");
      li.innerText = file.name;
      this.listNamesTarget.appendChild(li);
    })
  }


  clickButton() {
    this.inputTarget.click()
  }

  mouseOver() {
    this.listNamesTarget.classList.toggle('hidden', this.inputTarget.files.length == 0);
  }

  mouseOut() {
    this.listNamesTarget.classList.add('hidden');
  }

  inputChanged(e) {
    this.updateBadge()
    this.updateFileNames()
  }

  connect() {
    this.inputTarget.addEventListener('change', this.inputChanged)
  }
}
