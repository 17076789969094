import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["alert", "notice"]

  connect() {
    if (this.hasAlertTarget) {
      this.alertTarget.classList.remove('hidden');
      this.alertTarget.classList.add('transform', 'ease-out', 'duration-500', 'transition', 'translate-x-48', 'opacity-0');
      setTimeout(() => {
        this.alertTarget.classList.remove('translate-x-48', 'opacity-0');
        this.alertTarget.classList.add('translate-x-0', 'opacity-100');
      }, 50);
    }
    else if (this.hasNoticeTarget) {
      setTimeout(() => {
        this.noticeTarget.classList.remove('hidden');
        this.noticeTarget.classList.add('transform', 'ease-out', 'duration-500', 'transition', 'translate-x-48', 'opacity-0');

        setTimeout(() => {
          this.noticeTarget.classList.remove('translate-x-48', 'opacity-0');
          this.noticeTarget.classList.add('translate-x-0', 'opacity-100');
        }, 100);

      }, 500);

      setTimeout(() => {
        setTimeout(() => {
          this.noticeTarget.classList.remove('translate-x-0', 'opacity-100');
          this.noticeTarget.classList.add('translate-x-48', 'opacity-0');
          this.element.remove();
        }, 100);
      }, 3500);
    }
  }

  close() {
    this.element.classList.remove('transform', 'ease-out', 'duration-300', 'translate-x-0', 'opacity-0');
    this.element.classList.add('ease-in', 'duration-300')
    setTimeout(() => {
      this.element.classList.add('opacity-0');
    }, 100);
    setTimeout(() => {
      this.element.remove();
    }, 300);
  }
}
