require("flatpickr")
import flatpickr from "flatpickr";
import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect";
import { French } from "flatpickr/dist/l10n/fr.js"

const initFlatpickr = () => {
  flatpickr(".datepicker", {
    "locale": French,
    dateFormat: "d/m/Y",
  });
  flatpickr(".weekpicker", {
    weekNumbers: true,
    "locale": French,
    dateFormat: "d/m/Y",
    "plugins": [new weekSelect({})],
    "onChange": [function(){
      extractWeekNum(this)
    }],
    "onReady": [function(){
      extractWeekNum(this)
    }]
  });
}

const extractWeekNum = (flatpickr) => {
  let weeknumElem = document.querySelector(`#${flatpickr.element.id}_weeknum`)

  if (flatpickr.selectedDates[0]) {
    const weekNum = flatpickr.config.getWeek(flatpickr.selectedDates[0])
    let date = flatpickr.selectedDates[0]
    let day = date.getDay()
    let diff = date.getDate() - day + (day == 0 ? -6:1) // adjust when day is sunday
    let monday = new Date(date.setDate(diff))

    flatpickr.setDate(monday)

    if (weeknumElem) {
      weeknumElem.innerHTML = `S${weekNum}`
    }
  } else {
    weeknumElem.innerHTML = ''
  }
}

export { initFlatpickr };
