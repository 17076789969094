import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "results"]

  initialize() {
    this.searchAPICallback = this.searchAPICallback.bind(this)
  }

  connect() {
    this.intervalWaitBetween2Chars = 400 // ms
    this.pause = false
    this.requestInBuffer = false
    this.pauseBetweenRequestServer = 1000 // ms
  }

  displaySearchSuccess(json) {
    this.resultsTarget.innerHTML = ""
    json.data.forEach(element => {
      this.resultsTarget.insertAdjacentHTML('beforeend', element.html)
    });
    this.resultsTarget.classList.remove('hidden')
  }

  displaySearchNoResult(json) {
    this.resultsTarget.innerHTML = `<p class="text-center font-semibold">Aucun résultat pour: "${json.search}"</p>`
    this.resultsTarget.classList.remove('hidden')
  }

  displaySearchError(code_error) {
    this.resultsTarget.innerHTML = `<p class="text-red-500 text-center font-semibold">Une erreur est survenue. Code http: ${code_error}</p>`
    this.resultsTarget.classList.remove('hidden')
  }

  searchAPICallback(json) {
    console.log(json)
      if (json.code_error) {
        this.displaySearchError(json.code_error)
      } else if (json.total_count == 0) {
        this.displaySearchNoResult(json)
      } else {
        this.displaySearchSuccess(json)
      }
  }

  searchAPI() {
    this.pauseFetching()
    this.requestInBuffer = false
    console.debug(`je cherche.. [${this.inputTarget.value}]`)
    fetch(`/search/global?search=${this.inputTarget.value}`)
      .then(response => (response.status >= 400 && response.status < 600) ? { code_error: response.status } : response.json())
      .then(this.searchAPICallback)
  }

  pauseFetching() {
    this.pause = true
    setTimeout(() => {
      this.pause = false
      if (this.requestInBuffer) { this.searchAPI() }
    }, this.pauseBetweenRequestServer);
  }

  clearSearch() {
    this.resultsTarget.innerHTML = ""
    this.resultsTarget.classList.add('hidden')
  }

  searchHandler() {
    if (this.inputTarget.value.length > 2) {
      this.requestInBuffer = true
      if (!this.pause)
        this.searchAPI()
    } else {
      this.requestInBuffer = false
      this.clearSearch()
    }
  }

  inputChanged() {
    this.lastInput = Date.now()

    setTimeout(() => {
      if (Date.now() + 10 - this.lastInput > this.intervalWaitBetween2Chars)
        this.searchHandler()
    }, this.intervalWaitBetween2Chars);
  }


}
