// This controller works with specially annotated HTML like:
//
// <div data-controller="searchable-select-links">
//   <select data-searchable-select-links-target="selectTag">
//     <option value=""></option>
//     <option value="/toto">Toto</option>
//     <option value="/tata">Tata</option>
//   </select>
//
//   <a href="" class="opacity-50 pointer-events-none" data-searchable-select-links-target="link">
//     Sélectionner
//   </a>
// </div>

import 'select2'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectTag", "link" ]

  connect() {
    $(this.selectTagTarget).select2({
      width: "100%",
      allowClear: true,
      placeholder: this.selectTagTarget.getAttribute('placeholder')
    });

    $(this.selectTagTarget).on('change', this.itemSelected.bind(this))
  }

  itemSelected(e) {
    const classList = ['opacity-50', 'pointer-events-none']
    let link = this.linkTarget

    if (e.target.value === "") {
      link.href = ""
      link.classList.add(...classList)
    } else {
      link.href = e.target.value
      link.classList.remove(...classList)
    }
  }
}
