import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'button', 'section' ]

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();

    this.sectionTarget.classList.toggle('hidden')
    this.buttonTarget.classList.toggle('text-primary')
    this.buttonTarget.classList.toggle('border-primary')
  }

  hide() {
    this.sectionTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('text-primary')
    this.buttonTarget.classList.remove('border-primary')
  }

  initialize() {
    this.hide = this.hide.bind(this)
  }

  connect() {
    window.addEventListener('click', this.hide);
  }
}
